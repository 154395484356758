import { useGetLogoSalomQuery } from '@features/form-salom/api';

import { Button, If, InfoPayment, Input } from '@ui';
import { BASE_URL_LOGOS, TransactionTypes } from '@constants';
import { ICondition } from '@entities/conditions-salom';
import { getTypeSalomConditions, sortConditions } from '@utils';

import { IRegistrationForm } from './types';

export const RegistrationForm = ({
  handleSubmit,
  setFieldValue,
  errors,
  isValid,
  values,
  handleNextStep,
  isLoading,
  checkout,
  checkContractNumber,
  isTokenization,
}: IRegistrationForm) => {
  const handleCheckContractNumber = (): void => {
    checkContractNumber(Number(values?.contractNumber), {
      onSuccess: (data) => {
        if (data.code === 200) {
          handleNextStep();
        }
      },
    });
  };

  const { data: logo } = useGetLogoSalomQuery(values.contractNumber);

  const conditions: ICondition[] | undefined = checkout?.transactionTypes.find(
    (type) => type.groupName === TransactionTypes.Salom
  )?.conditions;

  const sortedConditions: ICondition[] = sortConditions(conditions || []);

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <Input
          maskChar=""
          mask="999999"
          placeholder="000000"
          className="w-[70%] sm:w-full"
          label="Номер карты Салом"
          initialValue={values.contractNumber}
          classNameEndIcon="!top-[50%] -translate-y-[50%]"
          onChange={(value) => setFieldValue('contractNumber', value)}
          errorText={errors.contractNumber || logo?.payload?.detailedInfo}
          isError={Boolean(
            errors.contractNumber || logo?.payload?.detailedInfo
          )}
          endIcon={
            <If
              condition={Boolean(
                logo?.payload?.brand && logo?.payload?.brand !== 'default.png'
              )}
            >
              <img
                alt="Logo"
                width={24}
                height={24}
                src={`${BASE_URL_LOGOS}/logos/${logo?.payload?.brand}`}
              />
            </If>
          }
        />
        <div className="grid grid-cols-6 items-center gap-2 sm:grid-cols-4">
          {sortedConditions?.map((option) => (
            <div
              key={option.id}
              className="flex w-full cursor-not-allowed flex-col items-center justify-center rounded-xl border border-grey-20 bg-grey-20 py-2 transition-all duration-200 hover:border-primary sm:w-full"
            >
              <div className="flex flex-col items-center justify-center">
                <p>{option?.durationMax}</p>
                <span className="text-grey-500">
                  {getTypeSalomConditions(option.durationType)}
                </span>
              </div>
            </div>
          ))}
        </div>
        <If condition={!isTokenization}>
          <InfoPayment
            group={false}
            className="mb-4"
            info={[{ label: 'К оплате', value: `${checkout?.amount} с.` }]}
          />
        </If>
        <Button
          isLoading={isLoading}
          disabled={!isValid}
          type="submit"
          onClick={() =>
            values.contractNumber ? handleCheckContractNumber() : null
          }
          className="justify-center sm:w-full"
        >
          Далее
        </Button>
      </form>
    </>
  );
};
