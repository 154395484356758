import { motion } from 'framer-motion';

import { BASE_URL_LOGOS } from '@constants';
import { If } from '@ui';

import { IHeaderStatusProps } from './types';
import { animate } from './constants';

export const HeaderStatus = ({ logo, title, reason }: IHeaderStatusProps) => {
  return (
    <div className="mb-8 flex flex-col items-center justify-center">
      <motion.img
        alt="Img"
        initial="initial"
        animate="animate"
        variants={animate}
        src={`${BASE_URL_LOGOS}/${logo}.svg`}
      />
      <If condition={Boolean(title)}>
        <h1 className="mt-8 text-2xl font-semibold">{title}</h1>
      </If>
      <If condition={Boolean(reason)}>
        <p className="sm:text-md mt-3 text-lg font-normal text-black sm:mt-2">
          {reason}
        </p>
      </If>
    </div>
  );
};
