import { useState } from 'react';

import { useCheckout } from '@hooks';

import { ContainerWrapper, parserTransactionsTypes, redirect } from '@utils';
import { If, Loader, Tabs } from '@ui';
import { useGetCheckoutQuery } from '@entities/checkout';
import { WebSites } from '@constants';

export const Home = () => {
  const [gate, setGate] = useState<string>('');
  const route: any = document.querySelector('meta[name="route"]');
  const url: string = route?.content;

  const defaultGate: any = document.querySelector('meta[name="defaultGate"]');

  const { handleSetCheckout } = useCheckout();
  const {
    data: checkout,
    isLoading,
    isSuccess,
    isError,
  } = useGetCheckoutQuery();

  if (isLoading) {
    return <Loader />;
  }

  if (isError && !url) {
    redirect(WebSites.AlifTj);
  }

  const [headers, childes] = parserTransactionsTypes(checkout);

  if (isSuccess) {
    handleSetCheckout(checkout);
  }

  setTimeout(() => {
    setGate(defaultGate?.content);
  }, 500);

  if (!gate) {
    return <Loader />;
  }

  return (
    <ContainerWrapper>
      <div className="my-[120px] sm:my-[44px]">
        <div className="mx-auto flex w-[65%] flex-col gap-10 sm:w-full sm:gap-2 sm:overflow-hidden">
          <If condition={headers.length !== 1}>
            <div className="mb-2 mt-10 sm:mt-0 sm:px-5">
              <p className="font-semibold">Выберите способ оплаты</p>
            </div>
          </If>
          <Tabs
            selectedDefault={gate}
            headers={headers}
            childes={childes}
            onChange={() => null}
          />
        </div>
      </div>
    </ContainerWrapper>
  );
};
