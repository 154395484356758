import { useEffect, useState } from 'react';

import clsx from 'clsx';
import { SalomCardIcon } from '@icons';

import { Button, If, InfoPayment, Input } from '@ui';
import { getTypeSalomConditions, numberFormat, sortConditions } from '@utils';

import { IConditionsProps } from './types';

import { ICondition } from '../../types';

export const Conditions = ({
  conditions,
  isPendingSendOtp,
  conditionId,
  sendOtp,
  contractNumber,
  handleNextStep,
  handleChangeCreditConditionId,
}: IConditionsProps) => {
  const sortedConditions: ICondition[] = sortConditions(conditions || []);

  const [condition, setCondition] = useState<ICondition | null>(
    sortedConditions.length ? sortedConditions[0] : null
  );

  const handleSelectValue = (value: ICondition): void => {
    setCondition(value);
    handleChangeCreditConditionId(value.id);
  };

  const handleSendOtp = (): void => {
    sendOtp(
      {
        creditConditionId: conditionId,
      },
      {
        onSuccess: (data) => {
          if (data.code === 200) {
            handleNextStep();
          }
        },
      }
    );
  };

  useEffect(() => {
    if (!conditions?.length) {
      handleNextStep();
    }
  }, []);

  return (
    <>
      <Input
        maskChar=""
        mask="999999"
        placeholder="000000"
        className="mb-3 w-[70%] sm:w-full"
        label="Номер карты Салом"
        disabled={true}
        initialValue={contractNumber}
        classNameEndIcon="!top-[38%] -translate-y-[38%]"
        endIcon={<SalomCardIcon />}
      />
      <If condition={Boolean(conditions?.length)}>
        <p className="mb-2 text-sm text-grey-500">Выберите условие</p>
        <div className="mb-10 grid grid-cols-6 items-center gap-2 sm:grid-cols-4">
          {sortedConditions?.map((option) => (
            <div
              key={option.id}
              onClick={() => handleSelectValue(option)}
              className={clsx(
                'flex w-full cursor-pointer flex-col items-center justify-center rounded-xl border py-2 transition-all duration-200 hover:border-primary sm:w-full',
                [
                  condition?.id === option.id
                    ? 'border-primary'
                    : 'border-grey-20',
                ],
                [condition?.id === option.id ? 'bg-white' : 'bg-grey-20']
              )}
            >
              <div className="flex flex-col items-center justify-center">
                <p
                  className={clsx([
                    condition?.id === option.id && 'font-semibold',
                  ])}
                >
                  {option?.durationMax}
                </p>
                <span className="text-grey-500">
                  {getTypeSalomConditions(option.durationType)}
                </span>
              </div>
            </div>
          ))}
        </div>
      </If>
      <InfoPayment
        className="mb-4"
        info={[
          {
            label: 'Комиссия',
            value: `${numberFormat(Number(condition?.fee))} c. ( ${condition?.clientCommission}%)`,
          },
          {
            label: 'Сумма к зачислению',
            value: `${numberFormat(Number(condition?.amount))} с.`,
          },
          {
            label: 'К оплате',
            value: `${numberFormat(Number(condition?.totalAmount))} с.`,
          },
        ]}
      />
      <Button
        isLoading={isPendingSendOtp}
        disabled={!conditionId}
        onClick={() => (contractNumber && conditionId ? handleSendOtp() : null)}
        type="submit"
        className="w-full justify-center"
      >
        Оплатить
      </Button>
    </>
  );
};
